import React, { useState, useEffect } from "react";
import * as S from "../styles/style.js";
import RankingList from "./rankingList.jsx";

const ShakingCharacter = ({ characterText, selectedID }) => {
  const [position, setPosition] = useState(0);
  const [moving, setMoving] = useState(true);
  const [showSpeechBubble, setShowSpeechBubble] = useState(false);
  useEffect(() => {
    let timer;
    if (moving) {
      timer = setInterval(() => {
        setPosition((prevPosition) => (prevPosition === 10 ? -10 : 10));
      }, 500);
    }
    return () => clearInterval(timer);
  }, [moving]);

  const handleClick = () => {
    if (showSpeechBubble) {
      setShowSpeechBubble(false);
      setMoving(true);
    } else {
      setMoving(false);
      setShowSpeechBubble(true);
    }
  };

  //console.log(characterText)
  return (
    <S.characterRankingBox>
      <div
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          right: "0",
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
        }}
      >
        {showSpeechBubble && (
          <S.SpeechBubble
            position={position}
            style={{
              position: "absolute",
              right: `${position}px`,
              transition: "right 0.5s linear",
            }}
          >
            {characterText}
          </S.SpeechBubble>
        )}
      </div>
      <S.characterRanking>
        <RankingList selectedID={selectedID} />
        {/* <img
          onClick={handleClick}
          src="/analysisKickkick.svg"
          alt="킥킥"
          style={{
            position: "absolute",
            right: `${position}px`,
            transition: "right 0.5s linear",
          }}
        /> */}
      </S.characterRanking>
    </S.characterRankingBox>
  );
};

export default ShakingCharacter;
