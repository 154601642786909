import styled, { css } from "styled-components";
import * as token from "../../../../../designToken";
import { isDesktop, isMobile } from "react-device-detect";









export const ModalText = styled.div`
  display: flex;
  align-items: left;
  margin-top: 5px;
  color: #666666;
  letter-spacing: -0.5px;
  font-size: 14px;
  font-weight: normal; // "regular" 대신 "normal" 사용
  font-family: "Pretendard";
`;


export const NotificationBtn = styled.button`
  position: relative;
  border: none;
  background: none;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const NotificationTxt = styled.div`
  white-space:pre-wrap;
  ${isDesktop &&
  css`
    position: absolute;
    z-index: 9;
    left: calc(100% + 28px); /* NotificationBtn의 오른쪽 끝에서 28px 떨어지게 배치 */
    width: 168px;
    padding: 14px 36px;
    border-radius: 10px;
    font-family: "Pretendard";
    font-size: 12px;
    font-weight: 400;
    letter-spacing: -0.5px;
    color: ${token.colors.grey_1500};
    background-color: ${token.colors.grey_100_};

    span {
      font-weight: 800;
    }

    ${NotificationBtn}:hover & {
      visibility: visible;
    }

    /* display: ${(props) => (props.isClicked ? "block" : "none")}; */
    visibility: ${(props) => (props.isClicked ? "visible" : "hidden")};
  `};
  ${isMobile &&
  css`
    position: absolute;
    z-index: 9;
    width: 130px;
    left: calc(100% + 5px);
    padding: 12px 12px;
    border-radius: 10px;
    font-family: "Pretendard";
    font-size: 12px;
    font-weight: 400;
    letter-spacing: -0.5px;
    color: ${token.colors.grey_1500};
    background-color: ${token.colors.grey_100_};

    span {
      font-weight: 800;
    }

    visibility: ${(props) => (props.isClicked ? "visible" : "hidden")};
  `}
`;



export const SpeechBubble = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border-radius: 20px;
  border: #2eb85d solid 1px;
  font-size: 13px;
  text-align: center;
  height: 35px;
  top: 12%;
  margin-top: -50px;
  margin-right: 7px;
  padding-left: 16px;
  padding-right: 16px;
  color: ${token.colors.grey_1500};

  &:after {
    content: "";
    position: absolute;
    border-style: solid;
    border-width: 20px 16px 0 17.5px;
    border-color: #ffffff transparent transparent transparent;
    display: block;
    width: 0;
    bottom: -18.5px;
    right: 10px;
  }

  &:before {
    content: "";
    position: absolute;
    border-style: solid;
    border-width: 20px 16px 0 17.5px;
    border-color: #2eb85d transparent transparent transparent;
    display: block;
    width: 0;
    bottom: -20px;
    right: 10px;
  }
`;


export const ContentBox = styled.div`
  position: relative;
  width: 90%;
  color: white;
`;



export const title = styled.div`
  width: 100%;
  display: flex;
  margin-top: 21px;
  flex-direction: column;
  align-items: flex-start;
  color: ${token.colors.grey_900};
  ${token.typography.appointment_topbar_16};
  font-size:24px;
`;

export const characterRankingBox = styled.div`
  position: relative;
  margin-top: 12px;
  width: 100%;
`;

export const characterRanking = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%; // 고정된 높이를 유지
`;


export const rankingRemains = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
  height: 100%; // 고정된 높이를 유지
`;

export const ranking = styled.div`
  display: flex;
  align-items: flex-end; /* 오타 수정 */
  width: 100%;
  justify-content: center;
  margin-top:10px;

  .rankingItem {
    display: flex;
    flex-direction: column; /* 이미지와 텍스트를 세로로 배치 */
  }

  .rankingItem img {
    border-radius: 100%; /* 이미지에 원형 스타일 */
    ${isDesktop &&
    css`
      width: 100px; /* 이미지 크기 조정 */
      height: 100px;
      margin-bottom: 25px; /* 이미지와 텍스트 사이의 간격 */
    `};
    ${isMobile &&
    css`
      width: 60px; /* 이미지 크기 조정 */
      height: 60px;
      margin-bottom: 15px; /* 이미지와 텍스트 사이의 간격 */
    `}
  }
  .rankingItem.remains {
    ${isDesktop &&
    css`
      align-items: right;
      margin-left: 40px;
      margin-right: 40px;
    `};
    ${isMobile &&
    css`
      align-items: center;
      margin-left: 10px;
      margin-right: 10px;
    `}
  }

  .rankingItem.andaRecommendation {
    align-items: center;
  }
  .rankingItem.andaRecommendation img {
    ${isDesktop &&
    css`
      width: 150px; /* 이미지 크기 조정 */
      height: 150px;
    `};
    ${isMobile &&
    css`
      width: 70px; /* 이미지 크기 조정 */
      height: 70px;
    `}
  }
  .rankingItem.andaRecommendation.infoTxt {
    color: ${token.colors.grey_1500};
    font-size: 12px;
    text-align: center;
    font-weight: bold;
  }
  .rankingItem.infoTxt {
    color: ${token.colors.grey_1500};
    font-size: 10px;
    text-align: center;
    font-weight: bold;
  }
`;

export const fontBold = styled.div`
  font-weight: 800;
  display: inline; /* 인라인으로 변경하여 줄바꿈 방지 */
`;

export const graphBtnBox = styled.div`
  display: flex;
  width: 100%;
`;
export const graphBtn = styled.button`
  align-self: flex-start;
  background: none;
  padding: 8px;

  border: 1px solid ${(props) => (props.selected ? `${token.colors.green_300}` : `${token.colors.grey_900_}`)};
  background-color: ${(props) => (props.selected ? `${token.colors.green_150_}` : `${token.colors.grey_100_}`)};
  color: ${token.colors.grey_1500};

  border-radius: 100px;
  font-size: 14px;
  font-weight: 500;
  font-family: "Pretendard";
  letter-spacing: -0.5px;
  color: ${token.colors.grey_1500};
  cursor: pointer;
  margin-right: 10px;
`;

export const GraphContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 22.5px;
  height: auto;
  width: 100%;
`;

export const GraphBar = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
`;

export const HospitalImage = styled.img`
    ${isDesktop &&
    css`
      width: 100px; /* 이미지 크기 조정 */
      height: 100px;
    `};
    ${isMobile &&
    css`
      width: 60px; /* 이미지 크기 조정 */
      height: 60px;
    `}
  margin-right: 10px;
  border-radius: 50%;
`;

export const GraphInfoContainer = styled.div`
  height: 100%;
  margin-left: 25px;
  align-items: center;
  justify-content: center;
`;
export const HospitalName = styled.div`
  color: ${token.colors.black_900} !important;
  font-size: 14px;
  font-weight: bold;
`;
export const BarContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
`;
export const Bar = styled.div`
  transition: width 0.3s ease;
  border-radius: 100px;

  width:${({ width }) => (width)};
  height: 15px;
`;

export const BarValue = styled.div`
  margin-left: 12px;
  align-items: center;
  color: ${token.colors.grey_1500};
  
  ${isDesktop &&
    css`
      font-size: 13px;
    `};
  ${isMobile &&
    css`
      font-size: 11px;
    `}
`;
