import styled, { css } from "styled-components";
import * as token from "../../../../../designToken";
import Rate from "rc-rate";
import { isMobile, isDesktop } from "react-device-detect";

export const CallContainer = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  width: fit-content;
  right: 3vw;
  bottom: 3vh;
  z-index: 10;
`;

export const CallBtn = styled.img`
  width: 8vw;
  cursor: pointer;
  // 태블릿 크기 미만이면
  @media (max-width: 767px) {
    right: 4vw;
    width: 100%;
  }
`;

export const PhoneNumber = styled.div`
  display: ${(props) => (props.hidden ? "none" : "flex")} !important;
  position: relative;
  display: flex;
  align-items: center;
  height: fit-content;
  padding: 20px;
  border-radius: 10px;
  font-family: "Pretendard";
  letter-spacing: -0.5px;
  color: ${token.colors.grey_1500};
  background-color: ${token.colors.grey_300_};
`;

export const CopyBtn = styled.button`
  border: none;
  margin-left: 8px;
  font-family: "Pretendard";
  font-size: 16px;
  font-weight: 600;
  color: #5f93ff;
  cursor: pointer;
`;

export const Toast = styled.div`
  position: fixed;
  /* right: calc(${(props) => props.callBtnWidth}px + 3vw);
  bottom: calc(${(props) => props.callBtnHeight}px + 3vh - 8px); */
  /* right: 50%; */
  bottom: 3vh;
  left: 50%; /* 화면 왼쪽에서 50% 이동 */
  transform: translateX(-50%); /* 자신의 너비의 절반만큼 왼쪽으로 이동 */
  padding: 12px;
  border-radius: 10px;
  font-family: "Pretendard";
  font-weight: 500;
  color: white;
  background-color: ${token.colors.grey_600};
`;

export const ClinicImg = styled.img`
  /* position: relative; */
  aspect-ratio: 1/1;
  width: 100%;
  z-index: -100;
`;

export const ClinicInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
  /* height: 280px; */
  height: fit-content;
  background-color: white;
  box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding: 22px;
  margin-top: -260px;
  font-family: "Pretendard";
  letter-spacing: -0.5px;
  z-index: 5;
  /* align-self: center; */

  // 태블릿 크기 미만이면
  @media (max-width: 767px) {
    margin-top: -260px;
    z-index: 5;
  }
  // 1200px 이상이면
  @media (min-width: 1200px) {
    margin-top: -260px;
  }
`;

export const ClinicName = styled.div`
  font-size: 36px;
  font-weight: bold;
  color: ${token.colors.grey_500};
  margin-bottom: 10px;
`;

export const Address = styled.div`
  /* ${token.typography.appointment_r_17}; */
  font-size: 20px;
  font-weight: 400;
  letter-spacing: -0.5px;
  color: ${token.colors.grey_1000};
  margin-bottom: 20px;
`;

export const ChartContainer = styled.div`
  width: 100%;
`;

export const Chart = styled.div`
  display: flex;
  align-items: center;
  border-radius: 72px;
  height: 32px;
  margin-bottom: 10px;
  font-family: "Pretendard";
  font-weight: 500;
  color: ${token.colors.grey_1500};
  letter-spacing: -0.5px;
  background-color: ${token.colors.grey_300_};
`;

export const ChartText = styled.div``;

export const ReviewSummary = styled.div`
  width: 80%;
  height: fit-content;
  margin-top: 30px;
  padding: 20px;
  border-radius: 15px;
  background-color: ${token.colors.green_150_};
`;

export const Header = styled.div`
  display: flex;
  height: 24px;
  margin: 0 0 12px 0;
  justify-content: space-between;
`;

export const Logo = styled.img`
  width: 52px;
  height: auto;
`;

export const Title = styled.div`
  display: flex;
  align-self: center;
  margin-left: 10px;
  font-family: Pretendard;
  letter-spacing: -0.5px;
  font-size: 18px;
  font-weight: bold;
  color: ${token.colors.grey_1500};
`;

export const VisitorReview = styled.button`
  border: none;
  background: none;
  font-family: "Pretendard";
  letter-spacing: -0.5px;
  font-size: 14px;
  color: ${token.colors.grey_1000};
  position: relative;
  cursor: pointer;
`;

export const Review = styled.div`
  display: flex;
  font-family: "Pretendard";
`;

export const Rating = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-right: 1px solid ${token.colors.grey_900_};
  padding: 8px;
  padding-right: 20px;
`;

export const CustomRate = styled(Rate)`
  white-space: nowrap;
  font-size: 16px !important; // 별 크기

  .rc-rate-star {
    margin-right: 1px; // 별 간격
  }

  // 별 색상
  .rc-rate-star-half .rc-rate-star-first,
  .rc-rate-star-full .rc-rate-star-second {
    color: ${token.colors.green_300};
  }
  .rc-rate-star-half:hover .rc-rate-star-first,
  .rc-rate-star-full:hover .rc-rate-star-second {
    color: ${token.colors.green_300};
  }
`;

export const NoRate = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  font-size: 20px;
  font-weight: bold;
  color: ${token.colors.green_300};
  border-right: 1px solid ${token.colors.grey_900_};
  padding: 8px;
  padding-right: 20px;
`;

export const Summary = styled.div`
  display: flex;
  align-self: center;
  margin-left: 20px;
  font-size: 16px;
  font-weight: 400;
  /* line-height: 26px; */
  color: ${token.colors.grey_1500};
  white-space: pre-wrap;
`;

export const DividingLine = styled.hr`
  width: 80%;
  margin: 20px;
  border: 1px solid ${token.colors.grey_300_};
`;

export const ReviewCheck = styled.div`
  width: 80%;
  font-family: "Pretendard";
  letter-spacing: -0.5px;
  margin-left: -30px;
`;

export const NotificationBtn = styled.button`
  position: relative;
  border: none;
  background: none;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const NotificationTxt = styled.div`
  ${isDesktop &&
  css`
    position: absolute;
    z-index: 9;
    left: calc(100% + 28px); /* NotificationBtn의 오른쪽 끝에서 28px 떨어지게 배치 */
    width: 168px;
    padding: 14px 36px;
    border-radius: 10px;
    font-family: "Pretendard";
    font-size: 12px;
    font-weight: 400;
    letter-spacing: -0.5px;
    color: ${token.colors.grey_1500};
    background-color: ${token.colors.grey_100_};

    span {
      font-weight: 800;
    }

    ${NotificationBtn}:hover & {
      visibility: visible;
    }

    /* display: ${(props) => (props.isClicked ? "block" : "none")}; */
    visibility: ${(props) => (props.isClicked ? "visible" : "hidden")};
  `};

  ${isMobile &&
  css`
    position: absolute;
    z-index: 9;
    width: 110px;
    left: calc(100% + 5px);
    padding: 12px 12px;
    border-radius: 10px;
    font-family: "Pretendard";
    font-size: 12px;
    font-weight: 400;
    letter-spacing: -0.5px;
    color: ${token.colors.grey_1500};
    background-color: ${token.colors.grey_100_};

    span {
      font-weight: 800;
    }

    visibility: ${(props) => (props.isClicked ? "visible" : "hidden")};
  `}
`;

export const ReviewBtn = styled.button`
  align-self: flex-start;
  background: none;
  margin: 14px 0 30px 0;
  padding: 8px;

  border: 1px solid ${(props) => (props.isClicked ? `${token.colors.green_300}` : `${token.colors.grey_900_}`)};
  background-color: ${(props) => (props.isClicked ? `${token.colors.green_150_}` : `${token.colors.grey_100_}`)};

  border-radius: 100px;
  font-size: 14px;
  font-weight: 500;
  font-family: "Pretendard";
  letter-spacing: -0.5px;
  color: ${token.colors.grey_1500};
  cursor: pointer;
`;

export const DisplayedReview = styled.div`
  display: flex;
  width: 80%;
  height: 100%;
  white-space: pre-wrap;
  height: fit-content;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 15px;
  font-family: "Pretendard";
  font-size: 16px;
  line-height: 26px;
  color: ${token.colors.grey_1500};
  word-break: break-all;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.1);
`;

export const NoReviewContainer = styled.div`
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Pretendard";
  letter-spacing: -0.5px;
`;

export const NoReview = styled.div`
  margin-top: 16px;
  text-align: center;
  /* font: ${token.typography.appointment_main_20}; */
  font-size: 24px;
  font-weight: bold;
`;

export const DetailedReview = styled.div`
  margin-top: 12px;
  font-size: 18px;
  font-weight: 400;
  color: ${token.colors.grey_1500};
`;

/**
 * keywordBar
 */

export const BarWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const Icon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 4px; // 아이콘과 글자 사이의 간격
`;

export const BackgroundBar = styled.div`
  width: 100%;
  height: 40px;
  background-color: ${token.colors.grey_300_};
  border-radius: 72px;
`;

export const PercentageBar = styled.div`
  width: ${(props) => props.width}%;
  height: 40px;
  background-color: ${token.colors.green_500_};
  display: flex;
  align-items: center;
  border-radius: 72px;
  padding-left: 8px;
  box-sizing: border-box;
  overflow: visible; /* 텍스트가 바깥으로 넘어갈 수 있도록 설정 */
`;

export const KeywordText = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: ${token.colors.grey_1500};
  white-space: nowrap; /* 텍스트 줄 바꿈 방지 */
`;
