import styled, { css } from "styled-components";
import * as token from "../designToken";

export const Main = styled.main`
  position: relative; // 부모를 상대 위치로 설정
  width: 50%;
  /* height: 100vh; */
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const nav_bar = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 8vh;
  width: 100%;
  justify-content: space-between;
  ${token.typography.appointment_main_20}
  text-align: center;
`;

export const top = styled.div`
  text-align: center;
  font-size: 24px;
`;

export const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.35);
  z-index: 5;
`;
export const ModalPopup = styled.div`
  position: absolute;
  top: 500%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 360px; // px 단위 추가
  height: 202.14px;
  overflow-y: auto;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 20px;
  z-index: 5;
`;
export const ModalBtn = styled.button`
  position: fixed; // absolute에서 fixed로 변경하여 항상 화면에 고정되게 함
  bottom: 0; // 버튼을 화면 하단에 위치시킴
  left: 50%; // 버튼을 화면 가로의 중앙에 위치시킴
  transform: translateX(-50%); // X축 기준 중앙 정렬을 위해 translateX 사용
  align-items: center;
  display: flex;
  overflow-y: auto;
  width: 360px; // px 단위 추가
  justify-content: center;
  background-color: ${token.colors.green_300};
  border: none;
  height: 60px;
  color: white;
  border-radius: 20px;
`;

export const ModalTextH = styled.div`
  ${token.typography.appointment_topbar_16};
  font-weight: "800";
  font-weight: bold; // "regular" 대신 "normal" 사용
  font-family: "Pretendard";
`;

export const ModalText = styled.div`
  color: #666666;
  font-size: 14px;
  font-weight: normal; // "regular" 대신 "normal" 사용
  font-family: "Pretendard";
`;

export const SortSelect = styled.select`
  background-color: ${token.colors.green_150_};
  border-color: ${token.colors.green_300};
  font-family: "Pretendard";
  font-weight: Medium;
  font-size: 14px;
  border-radius: 100px;
  color: ${token.colors.grey_1500};
  text-align: center;
  height: 30px;
  margin-left: 10px;
`;
export const SortOption = styled.option`
  background-color: white;
  border-radius: 10px;
  color: ${token.colors.grey_900_};
  ${(props) =>
    props.isSelected &&
    css`
      color: ${token.colors.grey_1500}; // 선택된 항목의 색상
    `}
`;
export const MapModal = styled.div`
  position: absolute;
  top: 25%;
  width: 400px;
  transform: translate(-50%, -50%);
  height: 618px;
  overflow-y: auto;
  background-color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 20px;
  z-index: 5;
`;
export const MapModalXBtn = styled.button`
  position: absolute;
  right: 4%;
  border: none;
  background-color: white;
  font-weight: 400;
  font-size: 20px;
  width: 65;
  height: 30;
`;

export const MapBtn = styled.div`
  background-color: ${token.colors.green_150_};
  font-family: "Pretendard";
  font-weight: Medium;
  font-size: 14px;
  border-radius: 100px;
  color: ${token.colors.grey_1500};
  border: 1px solid ${token.colors.green_300};
  width: 60px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
export const rowdiv = styled.div`
  width: 100%;
  flex-direction: row;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const MapNavText = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 6vh;
  width: 100%;
  justify-content: center;
  ${token.typography.appointment_topbar_16}
  text-align: center;
`;
export const MapInput = styled.input`
  border: none;
  display: flex;
  width: 80%;
`;
export const MapInputdiv = styled.div`
  border: none;
  border: 1px solid ${token.colors.green_300};
  border-radius: 100px;
  width: 350px;
  height: 40px;
  display: flex;
  align-items: center;
  margin: 10px;
  background-color: white;
  z-index: 1000;
`;
export const MainBtnImg = styled.img`
  height: 91.22px;
  width: 91.22px;
  border-radius: 15px;
`;
export const MainBtn = styled.div`
  margin-top: 12px;
  height: 120.07px;
  width: 550px;
  border: 2px solid;
  border-radius: 10px;
  border-width: 0;
  justify-content: space-around;
  align-items: center;
  display: flex;
  text-align: start;

  background-color: white;
  box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.1);
`;
export const MainBtnText = styled.div`
  display: flex;
  flex-direction: column;
  width: 270px;
`;
export const MainBtnInput = styled.input`
  appearance: none;
  width: 30px;
  height: 30px;
  border: 1.5px solid ${token.colors.green_300};
  border-radius: 0.35rem;
  &:checked {
    border-color: transparent;
    background-image: url("/checkbox.svg");
    background-size: 100% 100%;
    background-position: 50%;
    background-repeat: no-repeat;
    background-color: white;
    border: 1.5px solid ${token.colors.green_300};
  }
`;

export const ListName = styled.div`
  align-items: center;
  ${token.typography.appointment_topbar_16}
  color:black;
`;
export const ListTop = styled.div`
  display: flex;
  align-items: center;
  color: ${token.colors.grey_300};
  font-size: 15px;
`;
export const ListAddress = styled.div`
  padding-top: 8px;
  padding-bottom: 14px;
  color: ${token.colors.grey_1500};
  font-size: 14px;
  color: black;
`;
export const ListBotton = styled.div`
  color: ${token.colors.grey_1000};
  font-size: 15px;
`;

export const SpeechBubble = styled.div`
  position: absolute;
  width: 205.5px;
  top: -30px;
  left: 130%;
  transform: translateX(-50%);
  background-color: white;
  border: 1px solid black;
  border-radius: 10px;
  padding: 5px;
  text-align: center;
  font-size: 12px;
`;
export const ScrollButton = styled.button`
  position: fixed;
  bottom: 34px;
  width: 280px;
  height: 45px;
  left: 50%; // 버튼을 화면 가운데에 위치
  transform: translateX(-50%); // 중앙 정렬
  padding: auto;
  ${token.typography.ScanPage_r_17}
  background-color: #3adfb7;
  color: white;
  border: none;
  border-radius: 100px;
  cursor: pointer;
  z-index: 3;
   &:disabled {
    background-color: ${token.colors.grey_900_};
    cursor: not-allowed;
  }
`;

export const nav_bar_Analyze = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 8vh;
  width: 100%;
  justify-content: center;
  ${token.typography.appointment_main_20};
  text-align: center;
  img {
    position: absolute;
    left: 5vw;
  }
`;

export const ranking = styled.div`
  left: 5vw;
  width: 90%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
