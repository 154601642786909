import React, { useState, useEffect, useRef } from "react";
import districts from "../../../../config/districts";
import * as S from "../styles/style.js";

const Map = ({ initialArea, updateSucharea, closeMapModal }) => {
  const [query, setQuery] = useState(initialArea);
  const [suggestions, setSuggestions] = useState([]);
  useEffect(() => {
    // 모달이 열리면 body의 overflow를 hidden으로 설정
    document.body.style.overflow = "hidden";
    return () => {
      // 모달이 닫힐 때 overflow를 auto로 되돌림
      document.body.style.overflow = "auto";
    };
  }, []);

  const handleChange = (e) => {
    const value = e.target.value;
    setQuery(value);

    if (value.length > 0) {
      const regex = new RegExp(`${value}`, "i"); // 변경된 부분
      const newSuggestions = districts.sort().filter((district) => regex.test(district));
      setSuggestions(newSuggestions);
    } else {
      setSuggestions([]);
    }
  };

  const suggestionSelected = (value) => {
    setQuery(value);
    setSuggestions([]);
  };

  const renderSuggestions = () => {
    if (suggestions.length === 0) {
      return null;
    }
    return (
      <S.MapUl>
        {suggestions.map((item) => {
          const parts = item.split(new RegExp(`(${query})`, "gi"));
          return (
            <S.MapLi
              key={item}
              onClick={() => {
                suggestionSelected(item); // 선택된 항목 처리
              }}
            >
              {parts.map((part, index) =>
                part.toLowerCase() === query.toLowerCase() ? (
                  <span key={index} style={{ color: "#3ADFB7" }}>
                    {part}
                  </span>
                ) : (
                  part
                )
              )}
            </S.MapLi>
          );
        })}
      </S.MapUl>
    );
  };

  const handleComplete = () => {
    const modifiedQuery = query.replace("특별시", "").trim();
    updateSucharea(modifiedQuery);
  };

  const handleBackgroundClick = (e) => {
    if (e.target === e.currentTarget) {
      closeMapModal();
    }
  };
  const mapLocation = window.innerHeight * 0.2;

  return (
    <>
      <S.Background onClick={handleBackgroundClick}></S.Background>

      <S.MapModal
        style={{
          top: mapLocation,
        }}
      >
        <S.MapNavText>
          지역을 선택해주세요!
          <S.MapModalXBtn onClick={closeMapModal} src="/X-small.svg" style={{ width: "24px" }} />
        </S.MapNavText>
        <S.MapInputdiv>
          <img onClick={handleComplete} src="/mapSearchIcon.svg" alt="Search" style={{ marginLeft: "15px" }} />
          <S.MapInput type="text" value={query} onChange={handleChange} placeholder="# 서울 내 지역을 검색해보세요" />
          {renderSuggestions()}
        </S.MapInputdiv>

        {districts.includes(query) ? (
          <S.ScrollButton onClick={handleComplete}>완료</S.ScrollButton>
        ) : (
          <S.disableScrollButton disabled={true}>완료</S.disableScrollButton>
        )}
      </S.MapModal>
    </>
  );
};

export default Map;
