import React, { useEffect } from "react";
import * as S from "../styles/style.js";

function Modal({ isOpen, content, closeModal, closeImmediately }) {
  const contentComponents = {
    1: (
      <>
        <img
          src="/Modal1.svg"
          style={{
            marginTop: "20px",
            width: "108.81px",
            height: "98px",
            padding: "auto",
            alignItems: "center",
          }}
          alt=""
        />
        <S.ModalTextH>정렬 기준을 선택하여{`\n`}안과를 비교해보세요!</S.ModalTextH>
        <S.ModalText>안과를 선택하고 분석하기를 눌러보세요</S.ModalText>
      </>
    ),
    2: (
      <>
        <img
          src="/Modal2.svg"
          style={{
            marginTop: "20px",
            width: "108.81px",
            height: "98px",
            padding: "auto",
            alignItems: "center",
          }}
          alt=""
        />
        <S.ModalTextH>안과들을 다양한 기준으로{`\n`}비교해보세요!</S.ModalTextH>
        <S.ModalText>그래프를 통해 한눈으로 비교해요.</S.ModalText>
      </>
    ),
    3: (
      <>
        <img
          src="/Modal3.svg"
          style={{
            width: "108.81px",
            height: "98px",
            padding: "auto",
            marginTop: "20px",
            alignItems: "center",
          }}
          alt=""
        />
        <S.ModalTextH>조금 더 꼼꼼히{`\n`}안과를 확인해보세요!</S.ModalTextH>
        <S.ModalText>인공지능으로 리뷰를 요약했어요.</S.ModalText>
      </>
    ),
  };

  const handleNextClick = (event) => {
    event.stopPropagation(); // 이벤트 버블링 중지
    closeModal();
  };

  const displayContent = contentComponents[content] || <div>유효하지 않은 컨텐츠입니다.</div>;

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  return (
    <div style={{ display: isOpen ? "block" : "none" }}>
      <S.Background onClick={closeImmediately}>
        <S.ModalPopup onClick={(e) => e.stopPropagation()}>
          {contentComponents[content] || <div>유효하지 않은 컨텐츠입니다.</div>}
        </S.ModalPopup>
        <S.ModalBtn onClick={handleNextClick}>다음</S.ModalBtn>
      </S.Background>
    </div>
  );
}

export default Modal;
