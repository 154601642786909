import React, { useState } from "react";
import * as S from "../styles/style.js"; // 스타일 컴포넌트
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";


function HospitalGraph({ selectedClinics }) {
  // 정렬 기준 상태 관리
  const [sortOption, setSortOption] = useState("reviewCnt");
  const navigate = useNavigate();


  // 정렬 변경 함수
  const handleSortChange = (option) => {
    setSortOption(option);
  };
  const handleClick = (hospitalId) => {
    navigate('/Check', { state: { hospitalId: hospitalId } });
  };

  // 1등 병원의 기준 값 가져오기
  const maxValue = Math.max(...selectedClinics.map((hospital) => hospital[sortOption] * 1.2));

  return (
    <div style={{ width: "100%", marginTop:'20px' }}>
      <S.graphBtnBox>
        <S.graphBtn
          selected={sortOption === "reviewCnt"}
          onClick={() => handleSortChange("reviewCnt")}
        >
          리뷰순
        </S.graphBtn>
        <S.graphBtn
          selected={sortOption === "scoreAvg"}
          onClick={() => handleSortChange("scoreAvg")}
        >
          별점순
        </S.graphBtn>
        <S.graphBtn
          selected={sortOption === "blogCnt"}
          onClick={() => handleSortChange("blogCnt")}
        >
          블로그순
        </S.graphBtn>
      </S.graphBtnBox>

      <S.GraphContainer>
        {selectedClinics.map((hospital, index) => (
          <S.GraphBar key={index} onClick={() => handleClick(hospital.hospitalId)} >
            <div>
              <S.HospitalImage src={`/images/hospital_${hospital.hospitalId}.jpg`} alt={hospital.title}
                title={hospital.hospitalName}
              />
            </div>
            <S.GraphInfoContainer>
              <S.HospitalName>
                {hospital.hospitalName}
              </S.HospitalName>
              <S.BarContainer>
                <S.Bar
                  width={isMobile
                    ?`${(hospital[sortOption] / maxValue)*70}vw`
                    :`${(hospital[sortOption] / maxValue)*50}vw`} // Normal width calculation
                  style={{
                    backgroundColor: hospital[sortOption] === maxValue / 1.2 ? "#FBBB4F" : "#89E7D0", // Change color if it's the maximum value
                  }}
                />

                <S.BarValue>{hospital[sortOption]}</S.BarValue>
              </S.BarContainer>
            </S.GraphInfoContainer>
          </S.GraphBar>
        ))}
      </S.GraphContainer>
    </div>
  );
}

export default HospitalGraph;
