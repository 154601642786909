import React, { useState, useEffect } from "react";

const MoveCharacter = () => {
  const [position, setPosition] = useState(0);
  const [moving, setMoving] = useState(true);

  useEffect(() => {
    let timer;
    if (moving) {
      timer = setInterval(() => {
        setPosition((prevPosition) => (prevPosition === 10 ? -10 : 10));
      }, 500); // 캐릭터가 좌우로 움직이는 속도 조절
    }
    return () => clearInterval(timer);
  }, [moving]);

  const handleClick = () => {
    setMoving(!moving); // 클릭 시 움직임을 멈추거나 재개
  };

  return (
    <div
      onClick={handleClick}
      style={{
        left: "0px",
        position: "relative",
        width: "80px",
        height: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
      }}
    >
      <img
        src="/analysisKickkick.svg"
        alt="캐릭터"
        style={{
          width: "56px",
          height: "auto",
          position: "relative",
          left: `${position}px`,
          transition: "left 0.5s linear",
        }}
      />
    </div>
  );
};

export default MoveCharacter;
