import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Scan from "../src/APP/screens/main/ScanPage/scanPage";
import Analyze from "../src/APP/screens/main/AnalysisPage/analysis.home";
import Check from "../src/APP/screens/main/CheckPage/checkPage";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Scan />} />
        <Route path="/Analyze" element={<Analyze />} />
        <Route path="/Check" element={<Check />} />
      </Routes>
    </Router>
  );
}

export default App;
