import * as S from "../styles/style";

function KeywordBar({ keyword, icon, frequency }) {
  return (
    <S.BarWrapper>
      <S.BackgroundBar>
        <S.PercentageBar width={frequency}>
          <S.Icon src={icon} alt="icon" />
          <S.KeywordText>
            <span>{keyword}</span> <span style={{ marginLeft: "4px" }}></span>
          </S.KeywordText>
        </S.PercentageBar>
      </S.BackgroundBar>
    </S.BarWrapper>
  );
}

export default KeywordBar;
