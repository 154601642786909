import axios from "axios";

export const getHospitalDetailInfo = async (hospitalId) => {
  try {
    const info = await axios.get(`${process.env.REACT_APP_API_URL}/hospital/detail?hospitalId=${hospitalId}`);

    const hospitalDetailInfo = {
      hospitalId: info.data.result.hospitalId,
      hospitalName: info.data.result.hospitalName,
      profileThumbnail: info.data.result.profileThumbnail,
      location: info.data.result.location,
      connectNumber: info.data.result.connectNumber,
      reviewURL: info.data.result.reviewURL,
      keywords: info.data.result.keywords.map((keywords) => ({
        keyword: keywords.keyword,
        keywordRate: keywords.keywordRate,
      })),
      reviewSort: info.data.result.reviewSort,
      reviewCnt: info.data.result.reviewCnt,
      scoreAvg: info.data.result.scoreAvg,
      blogCnt: info.data.result.blogCnt,
    };

    console.log("js파일 hospitalDetailInfo", hospitalDetailInfo);
    return hospitalDetailInfo;
  } catch (error) {
    console.error("안과 세부정보 가져오기 실패:", error);
    throw error;
  }
};
