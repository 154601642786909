import React, { useEffect, useState, useRef } from "react";
import { createPortal } from "react-dom";
import { isMobile, isDesktop } from "react-device-detect";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useLocation, useNavigate } from "react-router-dom";
import * as S from "./styles/style.js";
import * as G from "../style.js";
import * as token from "../../../../designToken.js";
import { getHospitalDetailInfo } from "./apis/get.HospitalDetailInfo.js";
import { getHospitalReviewList } from "./apis/get.HospitalReviewList.js";
import KeywordBar from "./components/keywordBar.jsx";
import "rc-rate/assets/index.css";

function CheckPage() {
  const location = useLocation();
  const { hospitalId } = location.state || {}; // 전달된 데이터를 받아옵니다.

  // 안과 세부정보
  const [detailInfo, setDetailInfo] = useState({
    hospitalName: "",
    profileThumbnail: "",
    connectNumber: "",
    location: "",
    reviewURL: "",
    // 차트
    reviewCnt: 0,
    scoreAvg: 0.0,
    keywords: [],
    reviewSort: "",
    blogCnt: 0,
  });

  const [reviewList, setReviewList] = useState({
    reviewSort: 0,
    positiveReviews: [],
    negativeReviews: [],
  });

  // 서버에서 데이터 받아오기
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [info, list] = await Promise.all([getHospitalDetailInfo(hospitalId), getHospitalReviewList(hospitalId)]);

        setDetailInfo({
          hospitalName: info.hospitalName,
          profileThumbnail: info.profileThumbnail,
          connectNumber: info.connectNumber,
          location: info.location,
          keywords: info.keywords,
          reviewSort: info.reviewSort,
          reviewCnt: info.reviewCnt,
          reviewURL: info.reviewURL,
          scoreAvg: info.scoreAvg,
          blogCnt: info.blogCnt,
        });

        setReviewList(list);

        // console.log("detailInfo:", detailInfo);
        // console.log("blogList:", blogList);
      } catch (error) {
        console.error("데이터 가져오기 실패", error);
      }
    };
    fetchData();
  }, [hospitalId]);

  // reviewSort 수정
  const [mobileReviewSort, setMobileReviewSort] = useState("");

  useEffect(() => {
    if (detailInfo.reviewSort) {
      // let mobileReviewSort = detailInfo.reviewSort.split("\n\n")[0];
      //console.log(mobileReviewSort);
      setMobileReviewSort(detailInfo.reviewSort.split("\n\n")[0]);
    }
  }, [detailInfo]);

  // ClinicInfo 높이
  const [infoHeight, setInfoHeight] = useState(0);
  const infoRef = useRef(null);

  useEffect(() => {
    if (infoRef.current) {
      setInfoHeight(infoRef.current.clientHeight);
      //console.log(infoHeight);
    }
  }, [infoHeight]);

  // 키워드 차트 이미지 경로
  const icons = ["/red.svg", "/yellow.svg", "/green.svg", "/blue.svg"];

  // 전화 버튼(pc)
  const [isCallClicked, setIsCallClicked] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const handelCallClick = () => {
    setIsCallClicked(!isCallClicked);
  };

  const handleCopy = () => {
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
    }, 3000);
  };

  const [callBtnWidth, setCallBtnWidth] = useState(0);
  const [callBtnHeight, setCallBtnHeight] = useState(0);
  const callBtnRef = useRef(null);

  useEffect(() => {
    if (callBtnRef.current) {
      setCallBtnWidth(callBtnRef.current.clientWidth);
      setCallBtnHeight(callBtnRef.current.clientHeight);
    }
  }, []);

  // 리뷰 필터 버튼
  const [isPositiveClicked, setIsPositiveClicked] = useState(true);
  const [isNegativeClicked, setIsNegativeClicked] = useState(false);

  const handlePositiveClick = () => {
    setIsPositiveClicked(true);
    setIsNegativeClicked(false);
  };

  const handleNegativeClick = () => {
    setIsPositiveClicked(false);
    setIsNegativeClicked(true);
  };

  const displayedReviews = isPositiveClicked ? reviewList.positiveReviews : reviewList.negativeReviews;
  const sort = isPositiveClicked ? "긍정적인" : "부정적인";

  // 도움말 버튼
  const [isNotificationClicked, setIsNotificationClicked] = useState(false);
  const handleNotificationClick = () => {
    setIsNotificationClicked(!isNotificationClicked);
  };

  const navigate = useNavigate();
  const handleLogoClick = () => {
    navigate("/");
  };

  return (
    <G.Main>
      <G.Nav>
        <img src="/Nav_logo.png" alt="로고" onClick={handleLogoClick} />
        <span>안과 확인하기</span>
      </G.Nav>

      {/* 전화 버튼 */}
      <S.CallContainer>
        <S.PhoneNumber hidden={!isCallClicked}>
          {detailInfo.connectNumber}
          <CopyToClipboard text={detailInfo.connectNumber} onCopy={handleCopy}>
            <S.CopyBtn>복사</S.CopyBtn>
          </CopyToClipboard>
        </S.PhoneNumber>
        {isMobile && (
          <S.CallBtn src="/callBtn.svg" onClick={() => (window.location.href = `tel:${detailInfo.connectNumber}`)} />
        )}
        {isDesktop && <S.CallBtn src="/callBtn.svg" onClick={handelCallClick} ref={callBtnRef} />}
      </S.CallContainer>
      {showToast &&
        createPortal(
          <S.Toast callBtnWidth={callBtnWidth} callBtnHeight={callBtnHeight}>
            클립보드에 복사되었어요!
          </S.Toast>,
          document.body
        )}

      <S.ClinicImg src={`/images/hospital_${hospitalId}.jpg`} />
      <S.ClinicInfo infoHeight={infoHeight}>
        <S.ClinicName>{detailInfo.hospitalName}</S.ClinicName>
        <S.Address>{detailInfo.location}</S.Address>
        <S.ChartContainer>
          {detailInfo.keywords.map((item, index) => (
            <KeywordBar
              key={index}
              icon={icons[index]} // 아이콘 경로
              keyword={item.keyword}
              frequency={item.keywordRate}
            />
          ))}
        </S.ChartContainer>
      </S.ClinicInfo>
      <S.ReviewSummary>
        <S.Header>
          <div style={{ display: "flex" }}>
            <S.Logo src="/Nav_logo.png" alt="로고" />
            <S.Title>리뷰 요약</S.Title>
            <S.NotificationBtn onClick={handleNotificationClick}>
              <img src="/notification.png" style={{ width: "16px", height: "auto" }} alt="" />
              <S.NotificationTxt isClicked={isNotificationClicked}>
                인공지능을 통해
                <br />
                <span>리뷰</span> 내용을 <span>요약</span>했어요!
              </S.NotificationTxt>
            </S.NotificationBtn>
          </div>
          <S.VisitorReview onClick={() => window.open(detailInfo.reviewURL)}>
            방문자 리뷰 {detailInfo.reviewCnt}개 &gt;
          </S.VisitorReview>
        </S.Header>

        <S.Review>
          {detailInfo.scoreAvg === 0 ? (
            <S.NoRate>별점 없음</S.NoRate>
          ) : (
            <S.Rating>
              <div
                style={{
                  fontSize: "30px",
                  fontWeight: "bold",
                  color: token.colors.green_300,
                }}
              >
                {detailInfo.scoreAvg}
              </div>
              <S.CustomRate value={detailInfo.scoreAvg} allowHalf disabled />
            </S.Rating>
          )}

          <S.Summary>{isDesktop ? `${detailInfo.reviewSort}` : `${mobileReviewSort}`}</S.Summary>
        </S.Review>
      </S.ReviewSummary>
      <S.DividingLine />

      <S.ReviewCheck>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span style={{ fontSize: "24px", fontWeight: "bold" }}>핵심 리뷰</span>
        </div>
        <div style={{ display: "flex" }}>
          <span
            style={{
              fontWeight: "400",
              fontSize: "14px",
              color: token.colors.grey_1500,
              letterSpacing: "-0.5px",
              marginTop: "5px",
            }}
          >
            비중 있는 리뷰들을 우선적으로 확인해보세요.
          </span>
        </div>
      </S.ReviewCheck>
      <div style={{ width: "80%", marginLeft: "-30px" }}>
        <S.ReviewBtn onClick={handlePositiveClick} isClicked={isPositiveClicked}>
          긍정적 리뷰
        </S.ReviewBtn>
        <S.ReviewBtn onClick={handleNegativeClick} isClicked={isNegativeClicked} style={{ marginLeft: "10px" }}>
          부정적 리뷰
        </S.ReviewBtn>
      </div>
      {displayedReviews.length > 0 ? (
        displayedReviews.map((review, index) => <S.DisplayedReview key={index}>{review}</S.DisplayedReview>)
      ) : (
        <S.NoReviewContainer>
          <img src="chracters.svg" alt="" />
          <S.NoReview>
            {sort} 리뷰가 없어요!
            <S.DetailedReview>
              자세한 정보는 네이버 리뷰와
              <br />
              블로그를 통해 확인해보세요 :)
            </S.DetailedReview>
          </S.NoReview>
        </S.NoReviewContainer>
      )}
    </G.Main>
  );
}

export default CheckPage;
