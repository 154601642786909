import axios from "axios";

export const getHospitalReviewList = async (hospitalId) => {
  try {
    const list = await axios.get(`${process.env.REACT_APP_API_URL}/hospital/review/list?hospitalId=${hospitalId}`);

    const hospitalReviewList = {
      reviewSort: list.data.result.reviewSort,
      positiveReviews: list.data.result.positiveReviews,
      negativeReviews: list.data.result.negativeReviews,
      // negativeReviews: [],
    };

    // console.log("js파일 hospitalReviewList", hospitalReviewList);
    return hospitalReviewList;
  } catch (error) {
    console.error("안과 리뷰 가져오기 실패:", error);
    throw error;
  }
};
