import React, { useState, useEffect } from "react";
import * as S from "../styles/style.js";
import { useNavigate } from "react-router-dom";
import { getAnalysisAnda } from "../apis/get.Analysis.Anda.js";

const RankingList = ({ selectedID }) => {
  const navigate = useNavigate();
  const [anda, setAnda] = useState({});
  useEffect(() => {
    if (selectedID.length > 0) {
      const hospitalIds = selectedID.map((id, index) => `hospitalId${index + 1}=${id}`).join("&");
      const fetchData = async () => {
        try {
          const [andaResponse] = await Promise.all([getAnalysisAnda(hospitalIds)]);

          if (andaResponse.data.isSuccess) {
            setAnda(andaResponse.data.result);
          } else {
            console.error("ANDA 데이터를 가져오는 데 실패했습니다:", andaResponse.data.message);
          }
        } catch (error) {
          console.error("데이터를 가져오는 데 실패했습니다:", error);
        }
      };

      fetchData();
    }
  }, [selectedID]);

  const handleClick = (id) => {
    navigate("/Check", { state: { hospitalId: id } });
  };

  return (
    <S.ranking>
      {/* 안다의 추천 항목 */}
        <div className="rankingItem andaRecommendation">
          <img
           src={`/images/hospital_${anda.topHospital?.hospitalId}.jpg`}
            alt="안다의 추천"
            onClick={() => handleClick(anda.topHospital?.hospitalId)}
            title={anda.topHospital?.hospitalName} // 마우스 오버 시 병원명 표시
          />
          <div className="rankingItem andaRecommendation infoTxt">안다의 추천</div>
        </div>
        <S.rankingRemains>
        {/* 리뷰 1등 항목 */}
        <div className="rankingItem remains">
          <img
           src={`/images/hospital_${anda.topReviewHospital?.hospitalId}.jpg`}
            alt="리뷰 1등"
            onClick={() => handleClick(anda.topReviewHospital?.hospitalId)}
            title={anda.topReviewHospital?.hospitalName} // 마우스 오버 시 병원명 표시
          />
          <div className="rankingItem infoTxt">리뷰 1등</div>
        </div>

        {/* 블로그 1등 항목 */}
        <div className="rankingItem remains">
          <img
           src={`/images/hospital_${anda.topBlogHospital?.hospitalId}.jpg`}
            alt="블로그 1등"
            onClick={() => handleClick(anda.topBlogHospital?.hospitalId)}
            title={anda.topBlogHospital?.hospitalName} // 마우스 오버 시 병원명 표시
          />
          <div className="rankingItem infoTxt">블로그 1등</div>
        </div>

        {/* 별점 1등 항목 */}
        <div className="rankingItem remains">
          <img
           src={`/images/hospital_${anda.topRatingHospital?.hospitalId}.jpg`}
            alt="별점 1등"
            onClick={() => handleClick(anda.topRatingHospital?.hospitalId)}
            title={anda.topRatingHospital?.hospitalName} // 마우스 오버 시 병원명 표시
          />
          <div className="rankingItem infoTxt">별점 1등</div>
        </div>
        </S.rankingRemains>
    </S.ranking>
  );
};

export default RankingList;
