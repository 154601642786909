import axios from "axios";
export const getAnalysisAnda = async (hospitalIds) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/analysis/ranking?${hospitalIds}` 
    );
    return response; // 필요한 데이터를 반환
  } catch (e) {
    console.error(e);
    return null; // 에러 발생 시 null 반환
  }
};
